import Layout from "../../components/layout/layout";
import "../../assets/styles/property.css";
import StepOwner from "./new";
import { useState } from "react";
import { useAuthUser } from "react-auth-kit";

const Owner = () => {
    const auth = useAuthUser();
    const [defaultUser, setDefault] = useState(false);

    return <Layout>
        <div className='w-100 d-flex justify-content-center flex-column' style={{ alignItems: "center" }}>
            <button onClick={() => setDefault(true)}  data-bs-toggle="modal" data-bs-target="#owner-creation-modal" className='btn btn-success btn-lg my-button'>Adicionar Novo Proprietario (Com base no usuário logado)</button>
            <button onClick={() => setDefault(false)} data-bs-toggle="modal" data-bs-target="#owner-creation-modal" className='btn btn-success btn-lg my-button mt-5'>Adicionar Novo Proprietario</button>
        </div>
        <div className="modal fade" id="owner-creation-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="owner-creation-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close c-btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body property-creation">
                        <StepOwner defaultUser={defaultUser} />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
}

export default Owner;