import { useEffect } from "react";
import "./style.css";

const Nav = () => {
    useEffect(() => {
        let action = window.location.pathname.substring(1, window.location.pathname.length) + '/';
        action = action.substring(0, action.indexOf('/'));
        if(!action) action = 'home';

        if(document.querySelector('.nav_link.active') != null) {
            document.querySelector('.nav_link.active')?.classList.toggle('active');
            setTimeout(() => {
                document.querySelector(`.nav_link.${action}`)?.classList.toggle('active');
            }, 300);
        }
    }, [])

    return <div className="l-navbar show nav-style" style={{overflow: 'hidden'}} id="nav-bar" >
        <nav className="nav">
            <div>
                <div className="nav_list">
                    <hr className="hidden-hr" />
                    <a href="/home" className="nav_link active home">
                        <i className='bx bx-home-alt nav_icon'></i>
                        <span className="nav_name">Home</span>
                    </a>
                    <hr className="hidden-hr" />
                    <a href="/contratos" className="nav_link contratos">
                        <i className='bx bx-file nav_icon'></i>
                        <span className="nav_name">Contratos</span>
                    </a>
                    <hr className="hidden-hr" />
                    <a href="/imoveis" className="nav_link imoveis">
                        <i className='bx bx-building-house nav_icon'></i>
                        <span className="nav_name">Imóveis</span>
                    </a>
                    <hr className="hidden-hr" />
                    <a href="/locatarios" className="nav_link locatarios">
                        <i className='bx bx-group nav_icon'></i>
                        <span className="nav_name">Locatários</span>
                    </a>
                    <hr className="hidden-hr" />
                    <a href="/faturas" className="nav_link faturas">
                        <i className='bx bx-wallet-alt nav_icon'></i>
                        <span className="nav_name">Faturas</span>
                    </a>
                    <hr className="my-account" />
                    <a href="/conta" className="nav_link conta">
                        <i className='bx bx-user-circle nav_icon'></i>
                        <span className="nav_name">Minha conta</span>
                    </a>
                </div>
            </div>
            {/*<a href="/conta" className="nav_link_signout">
                <i className='bx bx-log-out nav_icon'></i>
                <span className="nav_name">SignOut</span>
</a>*/}
        </nav>
    </div>
}

export default Nav;