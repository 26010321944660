
import Menu from "./menu";
import Nav from "./nav/nav";
import "../../assets/styles/navbar.css";
import "../../assets/styles/layout.css";
import "../../assets/styles/icons.css";
import NavMobile from "./nav-mobile";
import { useAuthUser } from 'react-auth-kit'
import { useEffect } from "react";
import "./style.css"

const Layout = (props) => {
    const auth = useAuthUser();
    useEffect(() => { 
        if (auth()?.profile?.id && auth()?.profile?.id != 1) window.location.href = "/sair" 
        if (auth()?.id_profile && auth()?.id_profile != 1) window.location.href = "/sair" 
    }, [])

    return (
        <>
            <main className="mainLayoutNav">
                <div id="navbar">
                    <Menu />
                </div>
                <div className="mainLayoutContent">
                    <Nav />
                    {props.children}
                    <NavMobile />
                </div>
                
            </main>
        </>

    )
}

export default Layout;