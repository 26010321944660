import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { Document, Page, pdf } from "@react-pdf/renderer";
import PIX from "react-qrcode-pix"

import Service from "../../services/invoice";
import Time from "../../hooks/time";
import Logo from "../../assets/images/logo_full.svg";
import { QRCode } from 'qrcode.react';

const ExternalInvoice = () => {
  // pdf.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdf.version}/pdf.worker.js`;
  let { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [pdfInvoice, setDocument] = useState(null);
  const [pix, setPix] = useState(null);
  const [poop, setPoop] = useState(null);

  const showPoopUp = (message) => setPoop(message);

  useEffect(() => {
    document.body.classList.add("bg-white");

    if (!id) window.location.href = "/";
    else {
      const invoice_id = parseInt(atob(id).split("_")[1]);
      Service.details(null, invoice_id, true).then((response) => {
        if (response?.invoice?.document) setDocument(`${response.invoice.document}`);
        else {
          const valorFinal = calcularValorFinal(response.invoice);
          response.invoice.late_total = valorFinal - parseFloat(response.invoice.total_value);
          response.invoice.total_value = valorFinal;
          setInvoice(response.invoice);
        }
      });
    }
  }, []);

  function calcularDiasAtraso(dataAtual) {
    const dataVencimento = new Date(dataAtual);  // Exemplo de data de vencimento
    const dataDeAtualizacao = new Date();
    
    if(new Date(dataDeAtualizacao.toDateString()) <= new Date(dataVencimento.toDateString()) ) return 0;

    const diffTime = Math.abs(dataDeAtualizacao - dataVencimento);
    const diasDeAtraso = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convertendo para dias
    return diasDeAtraso;
  }

  function calcularValorFinal(invoice) {
    // Extrair valores da fatura
    const divida = parseFloat(invoice.total_value);  // Valor original da fatura
    const multaPercentual = parseFloat(invoice.late_fee);  // Multa em percentual
    const diasAtraso = calcularDiasAtraso(invoice.due_date_actual);  // Função que calcula os dias de atraso
    const jurosMesPercentual = parseFloat(invoice.late_interest);  // Juros mensal em percentual

    if(diasAtraso === 0) return divida;

    // Calcular a multa
    const multa = divida * (multaPercentual / 100);

    // Calcular os juros
    const juros = divida * ((diasAtraso / 30) * (jurosMesPercentual / 100));

    // Calcular o valor final
    const valorFinal = divida + multa + juros;

    return Math.ceil(valorFinal);
  }

  const setViewPIX = (code) => setPix(code);

  return invoice ? (
    <>
      <div className="d-flex justify-content-center mt-5"><img src={Logo} /></div>
      <div className="container bg-white position-relative">
        <h1 className="my-4"><strong>Fatura</strong></h1>
        <hr />
        <div className="mb-3 d-flex justify-content-between">
          <div>
            <p><strong>Número da Fatura:</strong> {invoice.invoice_id}</p>
            <p><strong>Referência:</strong> {Time.formatToMMYYYY(invoice.due_date_actual)}</p>
            <p><strong>Vencimento:</strong> {Time.formatToDDMMYYYY(invoice.due_date_actual)}</p>
            <p><strong>Total:</strong> R$ {parseFloat(invoice.total_value) +
              invoice.additions?.reduce(
                (a, b) =>
                  parseInt(b.invoice_addition_type_id) !== 1
                    ? a + parseFloat(b.value)
                    : a - parseFloat(b.value),
                0
              )}</p>
            <p><strong>Status:</strong> <span className={`badge ${invoice.paid_out === "1" ? "c-tag-success" : invoice.due_date_actual < Time.formatToYYYYMMDD(new Date()) ? "c-tag-danger" : "c-tag-warning"}`}>{invoice.paid_out === "1"
              ? "Pago"
              : invoice.due_date_actual < Time.formatToYYYYMMDD(new Date())
                ? "Vencido"
                : "A Vencer"}</span></p>
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            {invoice.paid_out === "0" ?
              <>
                <PIX
                  onLoad={payload => setViewPIX(payload)}
                  pixkey={invoice.pix}
                  merchant={invoice.contact_name}
                  city={invoice.property_city}
                  amount={parseFloat(invoice.total_value) +
                    invoice.additions?.reduce(
                      (a, b) =>
                        parseInt(b.invoice_addition_type_id) !== 1
                          ? a + parseFloat(b.value)
                          : a - parseFloat(b.value),
                      0
                    )}
                /><br />
                Aponte sua câmera para o QR Code para pagar ou clique <br /> <br />
                <button onClick={() => {
                  navigator.clipboard.writeText(pix)
                  showPoopUp("O código PIX foi copiado para a área de transferência.")
                }} className="btn imob-button">Clique aqui para copiar</button>
                <br />
                {poop ? <div className="alert imob-alert imob-alert-success" role="alert">{poop}</div> : <></>}
              </> : <></>
            }
          </div>
        </div>
        <hr />
        <div className="mb-3">
          <h3><strong>Detalhes do Pagador</strong></h3>
          <p><strong>Nome:</strong> {invoice.tenant_name}</p>
          <p><strong>E-mail:</strong> {invoice.contact_email}</p>
          <p><strong>Imóvel:</strong> {invoice.property_address}, {invoice.property_number}</p>
          <p><strong>Cidade:</strong> <span className="text-capitalize">{invoice.property_city}</span></p>
        </div>
        <hr />
        <div className="mb-3">
          <h3><strong>Detalhe de Valores</strong></h3>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Observação</th>
                <th>Valor (R$)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aluguel</td>
                <td>Valor do Aluguel</td>
                <td>R$ {invoice.rent_value}</td>
              </tr>
              {invoice.condominium_value > 0 ?
                <tr>
                  <td>Condomínio</td>
                  <td>Valor do Condomínio</td>
                  <td>R$ {invoice.condominium_value}</td>
                </tr> : <></>}
              {invoice.iptu_value > 0 ?
                <tr>
                  <td>IPTU</td>
                  <td>Valor do IPTU</td>
                  <td>R$ {invoice.iptu_value}</td>
                </tr> : <></>}
              {invoice.additions?.map((addition) => (
                <tr>
                  <td>{addition.invoice_addition_type}</td>
                  <td>{addition.description}</td>
                  <td>R$ {addition.value}</td>
                </tr>
              ))}
              {invoice.late_total > 0 ? <tr>
                <td>Juros</td>
                <td>Valor do Juros</td>
                <td>R$ {invoice.late_total}</td>
              </tr> : <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ) : pdfInvoice ? <embed
    src={`data:application/pdf;base64,${pdfInvoice}`}
    type="application/pdf"
    width="100%"
    height="100%"
    style={{
      position: "fixed", // Faz com que o elemento fique fixo e sobreponha qualquer outro conteúdo
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }} /> : (
    <div className="container bg-white"></div>
  );
};

export default ExternalInvoice;
