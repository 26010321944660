import React, { useEffect, useState } from "react";
import { useAuthUser } from 'react-auth-kit';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import Tenant from "../../services/tenant";
import Owner from "../../services/owner";


const StepOwner = ({ defaultUser }) => {
    const auth = useAuthUser();

    const [form, setForm] = useState({ created_by: auth().id, name: "", nickname: "", document: "", contact_name: "", email: "", mobile: "" });
    const [mask, setMask] = useState("");
    const [isInvalid, setIsInvalid] = React.useState({ stepOne: true, stepTwo: true, stepThree: true, stepFour: true });
    const [submitResult, setSubmitResult] = React.useState({ loading: true, success: true });
    const [errorMessage, setErrorMessage] = React.useState("")
    const [maritalStatus, setMaritalStatus] = React.useState([])

    const submit = async (newForm) => {
        setIsInvalid({ ...isInvalid, stepFour: true });

        if (mask && !form.occupation && form.marital_status) {
            setIsInvalid({ ...isInvalid, stepFour: false });
            setErrorMessage("Preencha todos os campos");
        } else {

            let response;
            
            if(newForm) {
                response  = await Owner.create({
                    ...newForm,
                    document: newForm.document.replace(/[^\d]/g, ''),
                    mobile: newForm.mobile.replace(/[^\d]/g, '')
                });
            } else {
                response  = await Owner.create({
                    ...form,
                    document: form.document.replace(/[^\d]/g, ''),
                    mobile: form.mobile.replace(/[^\d]/g, '')
                });
            }
            
            if(response.id > 0) window.location.reload();
        }
    }

    const isValidEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    const formatPhoneNumber = (phoneNumber) => {
        const formattedPhoneNumber = phoneNumber.replace(/^(\d{2})\s?(\d{4,5})-?(\d{4})$/, '($1) $2-$3');
        return formattedPhoneNumber;
    };

    const handlePhoneChange = (e) => {
        const newPhoneNumber = e.target.value;
        const digitsOnly = newPhoneNumber.replace(/\D/g, '');
        const limitedDigits = digitsOnly.slice(0, 11);
        let formattedPhoneNumber = formatPhoneNumber(limitedDigits);
        if (limitedDigits.length >= 10 && limitedDigits.length <= 11) {
            formattedPhoneNumber = `${formattedPhoneNumber}`;
            setForm({ ...form, mobile: formattedPhoneNumber });
        } else {
            setForm({ ...form, mobile: limitedDigits });
        }
    };

    const switchStep = async (step, values) => {
        if (values != null) setForm(values);

        if (await validateStep(step)) {
            if ((step === 3 && mask) || step != 3) {
                document.querySelector(`.c-step-display-0${step + 1}`).classList.toggle('true');
                document.querySelector(`.c-step-view.c-step-0${step}`).classList.toggle('true');
                document.querySelector(`.c-step-view.c-step-0${step + 1}`).classList.toggle('true');
            } else {
                submit()
            }
        }
    }

    const validateStep = async (step) => {
        switch (step) {
            case 1:
                setIsInvalid({ ...isInvalid, stepOne: true });
                if (form.document && (form.document.length === 14 || form.document.length === 18)) {
                    if (!mask) {
                        const response = await Tenant.cnpj(form.document)
                        if (response.razao_social === undefined) {
                            setIsInvalid({ ...isInvalid, stepOne: false });
                            setErrorMessage("CNPJ inválido");
                            return false;
                        } else {
                            setForm({ ...form, name: response.razao_social, nickname: response.nome_fantasia })
                            setIsInvalid({ ...isInvalid, stepOne: true });
                            return true;
                        }
                    } else {
                        const response = await Tenant.cpf(form.document)
                        if (response.razao_social === undefined) {
                            setIsInvalid({ ...isInvalid, stepOne: false });
                            setErrorMessage("CPF inválido");
                            return false
                        }
                        else {
                            setForm({ ...form, name: response.razao_social })
                            setIsInvalid({ ...isInvalid, stepOne: true });
                            return true;
                        }
                    }
                } else {
                    setIsInvalid({ ...isInvalid, stepOne: false });
                    setErrorMessage("CPF inválido");
                    return false
                }
            case 2:
                setIsInvalid({ ...isInvalid, stepTwo: true });
                if (mask) setForm({ ...form, contact_name: form.name })
                if (form.name && (mask || form.nickname)) return true;
                else {
                    setIsInvalid({ ...isInvalid, stepTwo: false });
                    return false;
                }
            case 3:
                setIsInvalid({ ...isInvalid, stepThree: true });

                if (form.contact_name && form.email && form.mobile) {
                    if (!isValidEmail(form.email)) {
                        setIsInvalid({ ...isInvalid, stepThree: false });
                        setErrorMessage("E-mail inválido !");
                        return false;
                    }

                    const formattedPhoneNumber = formatPhoneNumber(form.mobile);
                    if (formattedPhoneNumber.length < 14) {
                        setIsInvalid({ ...isInvalid, stepThree: false });
                        setErrorMessage("Número de celular inválido !");
                        return false;
                    }

                    setForm({ ...form, mobile: formattedPhoneNumber });

                    return true;
                } else {
                    setIsInvalid({ ...isInvalid, stepThree: false });
                    setErrorMessage("Preencha todos os campos");
                    return false;
                }

            default:
                return false;
        }
    }

    const getMaritalStatus = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/owner/marital-status`);
        const data = await response.json();
        setMaritalStatus(data.data)
    }

    useEffect(() => {
        getMaritalStatus();
        const myModal = document.getElementById('owner-creation-modal') ?? document.getElementById('new-owner-modal');

        myModal.addEventListener('show.bs.modal', () => {
            let dialogModal = document.querySelector('#owner-creation-modal .modal-dialog');
            if (window.matchMedia("(max-width: 768px)").matches) {
                if (!dialogModal.classList.contains('modal-fullscreen')) dialogModal.classList.add('modal-fullscreen')
            } else dialogModal.classList.remove('modal-fullscreen')
        });

    }, [])

    useEffect(() => {
        if(defaultUser) {
            let newForm = {
                created_by: auth().id,
                name: auth().name + " " + auth().lastname,
                nickname: auth().name,
                document: auth().document, 
                contact_name: auth().name + " " + auth().lastname, 
                email: auth().email, 
                mobile: auth().mobile,
                occupation: null, 
                marital_status: null
            };

            const cleanValue = auth().document.replace(/[^\d]/g, '');

            if (cleanValue.length === 11) {
                document.querySelector(`.c-step-display-02`).classList.toggle('true');
                document.querySelector(`.c-step-display-03`).classList.toggle('true');
                document.querySelector(`.c-step-display-04`).classList.toggle('true');
                document.querySelector(`.c-step-view.c-step-01`).classList.toggle('true');
                document.querySelector(`.c-step-view.c-step-04`).classList.toggle('true');
            }
            else submit(newForm)
        } else {
            setForm({ created_by: auth().id, name: "", nickname: "", document: "", contact_name: "", email: "", mobile: "", occupation: null, marital_status: null });
        }
    }, [defaultUser])

    return <div className="property-creation">
        <div><h5 className="title">Cadastro de proprietário</h5></div>
        <div className="c-step">
            <span className="c-step-display c-step-display-01 true">Etapa 1</span>
            <span className="c-step-display c-step-display-02">Etapa 2</span>
            <span className="c-step-display c-step-display-03">Etapa 3</span>
            <span className="c-step-display c-step-display-04" style={{ borderRadius: '0 10px 10px 0' }}><i className='bx bx-check'></i></span>
        </div>
        <div className="c-step-view c-step-01 true">
            <center><p>Para facilitar o cadastro do proprietário, nos informe o CPF ou CNPJ que vamos buscar as informações para você.</p></center>
            {isInvalid.stepOne === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> {errorMessage}</strong>
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <CpfCnpj
                    value={form.document}
                    placeholder="000.000.000-00"
                    className="form-control c-input-option"
                    onChange={(ev, type) => {
                        setForm({ ...form, document: ev.target.value });
                        setMask(type === "CPF");
                    }}
                />
            </div>
            <div className="c-options-nav" style={{ justifyContent: 'flex-end' }}>
                <button onClick={() => switchStep(1)} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        <div className="c-step-view c-step-02">
            <p>Para seguirmos, por favor confirme se os dados estão
                corretos e defina um apelido para facilitar a busca.</p>
            {isInvalid.stepTwo === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> Preencha todos os campos</strong> antes de continuar.
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                <div className="mb-3 c-option-box">
                    <label htmlFor="nome" className="form-label">{mask ? <>Nome</> : <>Razão Social</>}</label>
                    {mask ? <input defaultValue={form.name} disabled={true} minLength="3" maxLength="512" onKeyUp={(evt) => setForm({ ...form, name: evt.target.value })} id="nome" className="form-control c-input-option-0" type="text" placeholder="Digite seu nome" />

                        : <input defaultValue={form.name} disabled={true} minLength="3" maxLength="512" onKeyUp={(evt) => setForm({ ...form, name: evt.target.value })} id="nome" className="form-control c-input-option-0" type="text" placeholder="Digite seu nome" />}

                </div>
                {!mask ? <>
                    <div className="mb-3 c-option-box">
                        <label htmlFor="nickname" className="form-label">Nome Fantasia</label>
                        <input defaultValue={form.nickname} disabled={false} maxLength="512" onKeyUp={(evt) => setForm({ ...form, nickname: evt.target.value })} id="nickname" className="form-control c-input-option-0" type="text" />
                    </div></> : <>
                    <div className="mb-3 c-option-box">
                        <label htmlFor="nickname" className="form-label">Apelido</label>
                        <input defaultValue={form.nickname} disabled={false} maxLength="512" onKeyUp={(evt) => setForm({ ...form, nickname: evt.target.value })} id="nickname" className="form-control c-input-option-0" type="text" />
                    </div></>}
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(1)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => switchStep(2)} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        <div className="c-step-view c-step-03">
            <p>Defina os dados de contato para enviarmos a minuta para assinatura.</p>
            {isInvalid.stepThree === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> {errorMessage}</strong>
                    </div>
                </div>
            </> : <></>}
            <div className="c-options">
                {mask ? <></> : <>
                    <div className="mb-3 c-option-box">
                        <label htmlFor="contact_name" className="form-label">Nome</label>
                        <input disabled={false} onKeyUp={(evt) => setForm({ ...form, contact_name: evt.target.value })} defaultValue={form.contact_name} id="contact_name" className="form-control c-input-option-0" placeholder="Nome" type="text" />
                    </div>
                </>}
                <div className="mb-3 c-option-box">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input onKeyUp={(evt) => setForm({ ...form, email: evt.target.value })} id="email" className="form-control c-input-option-0" placeholder="Digite seu e-mail" type="text" />
                </div>
                <div className="mb-3 c-option-box">
                    <label htmlFor="mobile" className="form-label">Telefone (Whatsapp)</label>
                    <input
                        placeholder="(__) _____-____"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={form.mobile}
                        onChange={handlePhoneChange}
                        maxLength={15}
                        className="inputRegistroTelefoneLocatario form-control c-input-option-0"
                        type='tel'
                    />
                </div >
            </div >
            <div className="c-options-nav">
                <button onClick={() => switchStep(2)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => switchStep(3)} className="btn c-next-step">Continuar</button>
            </div>
        </div >
        <div className="c-step-view c-step-04">
            <p>Para finalizar, inclua abaixo os dados complementares para inclusão na minuta do contrato de locação.</p>
            {isInvalid.stepFour === false ? <>
                <div className="w-100">
                    <div className="alert imob-alert imob-alert-danger" role="alert">
                        <i className='bx bx-info-circle'></i>
                        <strong> Preencha todos os campos</strong> antes de continuar.
                    </div>
                </div>
            </> : <></>}
            <div className="mb-3 c-option-box">
                <label htmlFor="marital-status" className="form-label">Estado civil</label>
                <select onChange={(evt) => setForm({ ...form, marital_status: evt.target.value })} id="marital-status" className="form-control c-input-option-0" placeholder="">
                    <option value={null}>Selecione o estado civil</option>
                    {maritalStatus && maritalStatus.length > 0 ? maritalStatus.map(item => <option key={item.id} value={item.id}>{item.description}</option>) : <></>}
                </select>
            </div>
            <div className="mb-3 c-option-box">
                <label htmlFor="occupation" className="form-label">Profissão</label>
                <input onKeyUp={(evt) => setForm({ ...form, occupation: evt.target.value })} id="occupation" className="form-control c-input-option-0" placeholder="Digite a profissão" type="text" />
            </div>
            <div className="c-options-nav">
                <button onClick={() => switchStep(3)} className="btn c-prev-step">Voltar</button>
                <button onClick={() => submit()} className="btn c-next-step">Continuar</button>
            </div>
        </div>
        <div className="c-step-view c-step-05 c-step-final justify-content-center p-5">
            {submitResult.loading ? <div className="spinner-border text-success" role="status"></div> : submitResult.success ? <div className="box-success">
                <span><i className='bx bxs-check-circle'></i></span>
                <h4>Cadastro finalizado!</h4>
                {/* <p>Agora você pode acessar os detalhes do seu Locatário e adicionar mais informações sobre ele..</p> */}
                <div className="c-options">
                    <a href={`/locatarios/detalhe/${submitResult.id}`} className="btn c-option my-button">Acessar detalhes do locatário</a>
                    <a href="/locatarios" className="btn c-option my-button">Voltar</a>
                </div>
            </div> : <>Ocorreu um erro inesperado :(</>}
        </div>
    </div >
}

export default StepOwner;